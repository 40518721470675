import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from "antd";

import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

    /*
    Mauve
    #B99095

    Salmon
    #FCB5AC

    Mint
    #B5E5CF

    Teal Green
    #3D5B59
     */

  // <React.StrictMode>
      <ConfigProvider theme={{
          token: {
              colorPrimary: "#B99095",
              colorTextHeading: "#B99095",
              borderRadius: 8,
          },
          components: {
              Layout: {

              },
              Form: {
                  // itemMarginBottom: 8,
                  // verticalLabelPadding: "0 0 4px"
              }
          }
      }}>
        <App />
      </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
